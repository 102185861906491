import React, { useState } from 'react';
import '../css/custom.css';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import { auth } from '../util/AuthHandler';

const DEFAULT_MODE = 'Online';

const AddExpense = () => {
    const [category, setCategory] = useState('');
    const [amount, setAmount] = useState('');
    const [mode, setMode] = useState(DEFAULT_MODE);
    const [statusMessage, setStatusMessage] = useState('');

    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
    };

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };

    const handleModeChange = (e) => {
        setMode(e.target.value);
    };

    const showStatusMessage = (msg) => {
        setStatusMessage(msg);
        setTimeout(() => setStatusMessage(''), 3000);
    };

    const handleSubmit = async () => {
        console.log('Category:', category);
        console.log('Amount:', amount);
        console.log('Mode:', mode);

        try {
            setStatusMessage('Updating...');
            const userId = auth.currentUser.uid;
            const docRef = await addDoc(collection(db, 'users', userId, 'expenses'), {
                category: category,
                amount: amount,
                mode: mode,
                date: Date.now(),
            })
            showStatusMessage('Updated.');
            setAmount('');
            setCategory('');
            setMode(DEFAULT_MODE);
            console.log('Document written with ID: ', docRef.id);
        } catch (e) {
            console.log('Error while adding: ', e);
            showStatusMessage('Error while updating.');
        }
    };

    return (
        <form>
            <h2>Add Expense</h2>
            <div className='form-container'>
                <label>
                    Category:
                    <input type='text' value={category} onChange={handleCategoryChange} />
                </label>
                <label>
                    Amount:
                    <input type='number' value={amount} onChange={handleAmountChange} />
                </label>
                <label>
                    Mode:
                    <select value={mode} onChange={handleModeChange}>
                        <option value='Online'>Online</option>
                        <option value='Cash'>Cash</option>
                    </select>
                </label>
            </div>
            <label id='statusMessage'>{statusMessage}</label>
            <button type='button' onClick={handleSubmit}>
                Submit
            </button>
        </form>
    );
};

export default AddExpense;
