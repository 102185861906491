import React from 'react';

const Home = () => {
  return (
    <div className="home-container">
      <h1>Welcome to KS Expense Manager</h1>
      <p>
        Manage your expenses with ease using KS Expense Manager. Keep track of your spending, categorize expenses,
        and gain insights into your financial habits.
      </p>
      <div>
        <p>Features:</p>
        <ul>
          <li>Track and categorize your expenses</li>
          <li>View a summary of your spending patterns</li>
          <li>Delete or modify existing expenses</li>
        </ul>
      </div>
      <p>Start managing your expenses today!</p>
      <p className="developer-info">Developed by Karthick Shiva</p>
    </div>
  );
};

export default Home;
