import GoogleButton from 'react-google-button'
import { GoogleAuthProvider, browserLocalPersistence, signInWithPopup } from "firebase/auth";
import { auth, isLoggedIn, provider } from '../util/AuthHandler';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';


const Login = ({setIsSignedIn}) => {
    const navigate = useNavigate();
    useEffect(() => {
        if (isLoggedIn()) {
            navigate('/');
        }
        setIsSignedIn(isLoggedIn());
    }, [navigate, setIsSignedIn]);

    async function signIn() {
        await auth.setPersistence(browserLocalPersistence);
        const result = await signInWithPopup(auth, provider);
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        console.log(user);
        console.log(token);
        setIsSignedIn(isLoggedIn());
        navigate('/')
    };

return (
    <form className='login-container'>
        <h1>Login to KS Expense Manager</h1>
        <GoogleButton onClick={signIn} />
    </form>
);

}

export default Login;