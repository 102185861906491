import { GoogleAuthProvider, getAuth, signOut } from "@firebase/auth";

export const provider = new GoogleAuthProvider();
export const auth = getAuth();

export const isLoggedIn = () => {
    return auth.currentUser != null;
}

export const logout = async () => {
    await signOut(auth);
}