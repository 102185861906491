import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, deleteDoc, doc, getDocs, orderBy, query } from 'firebase/firestore';
import { Table, Tbody, Tr, Td, Th, Thead } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import '../css/custom.css';
import { auth } from '../util/AuthHandler';

const ExpenseList = () => {
    const [expenses, setExpenses] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchExpenses = async () => {
        try {
            setLoading(true);
            const userId = auth.currentUser.uid;
            if(!userId) return;
            const q = query(collection(db, 'users', userId, 'expenses'), orderBy('date', 'desc'));
            const querySnapshot = await getDocs(q);
            const expenseData = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            setExpenses(expenseData);
        } catch (error) {
            console.error('Error fetching expenses: ', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchExpenses();
    }, []);

    const handleRefresh = () => {
        fetchExpenses();
    };

    const handleDelete = async (id) => {
        try {
            const userId = auth.currentUser.uid;
            await deleteDoc(doc(db, 'users', userId, 'expenses', id));
            fetchExpenses(); // Refresh the expense list after deletion
        } catch (error) {
            console.error('Error deleting expense: ', error);
        }
    };

    return (
        <div className="flex-container">
            <h2>View Expenses</h2>
            <div>
                <button onClick={handleRefresh}>Refresh</button>
            </div>
            {loading ? (
                <p>Loading expenses...</p>
            ) : (
                <Table className="expense-table">
                    <Thead>
                        <Tr>
                            <Th>Category</Th>
                            <Th>Amount</Th>
                            <Th>Mode</Th>
                            <Th>Date</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {expenses.map((expense) => (
                            <Tr key={expense.id}>
                                <Td>{expense.category}</Td>
                                <Td>₹{expense.amount}</Td>
                                <Td>{expense.mode}</Td>
                                <Td>{new Date(expense.date).toLocaleString()}</Td>
                                <Td>
                                    <button onClick={() => handleDelete(expense.id)}>Delete</button>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            )}
        </div>
    );
};

export default ExpenseList;
