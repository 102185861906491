import React from 'react';
import { Navigate } from 'react-router-dom';
import { isLoggedIn } from './AuthHandler';

const ProtectedRoute = ({ component: Component }) => {
    return (
        isLoggedIn() ? (
            <Component />
        ) : (
            <Navigate to="/Login" replace />
        )
    );
};

export default ProtectedRoute;
