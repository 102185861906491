import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AddExpense from './pages/AddExpense';
import ViewExpense from './pages/ViewExpense';
import SideNavigation from './SideNavigation';
import Home from './pages/Home';
import Login from './pages/Login';
import ProtectedRoute from './util/ProtectedRoute';
import { useState } from 'react';
import { isLoggedIn } from './util/AuthHandler';

function App() {
  const [isSignedIn, setIsSignedIn] = useState(isLoggedIn());
  return (
    <div className="app-container">
      <BrowserRouter>
        {isSignedIn && <aside>
          <SideNavigation />
        </aside>}
        <main>
          <Routes>
            <Route
              path='/'
              element={<ProtectedRoute component={Home} />}
            />
            <Route
              path='/Login'
              element={<Login setIsSignedIn={setIsSignedIn}/>}
            />
            <Route
              path="/AddExpense"
              element={<ProtectedRoute component={AddExpense} />}
            />
            <Route
              path="/ViewExpenses"
              element={<ProtectedRoute component={ViewExpense} />}
            />
          </Routes>
        </main>
      </BrowserRouter>
    </div>
  );
}

export default App;
