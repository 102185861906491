import { useState } from 'react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { IoIosAddCircle } from 'react-icons/io';
import { CiViewList } from "react-icons/ci";
import { FaEye } from "react-icons/fa";
import { IoHome, IoLogOut } from "react-icons/io5";
import { IoLogIn } from "react-icons/io5";
import { isLoggedIn, logout } from './util/AuthHandler';



function SideNavigation() {
    const [isCollapsed, setCollapsed] = useState(true);

    const handleMouseEnter = () => {
        setCollapsed(false);
    };

    const handleMouseLeave = () => {
        setCollapsed(true);
    };

    return (
        <Sidebar
            className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}
            collapsed={isCollapsed}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Menu>
                <MenuItem component={<Link to="/" />} icon={<IoHome />}>Home</MenuItem>
                <MenuItem component={<Link to="/AddExpense" />} icon={<IoIosAddCircle />}>Add Expense</MenuItem>
                <MenuItem component={<Link to="/ViewExpenses" />} icon={<FaEye />}>View Expenses</MenuItem>
                <MenuItem component={<Link to="/ViewExpenses" />} icon={<CiViewList />}>Report</MenuItem>
                {!isLoggedIn() ? <MenuItem component={<Link to="/Login" />} icon={<IoLogIn />}>Login</MenuItem> :
                    <MenuItem component={<Link to="/Login" />} icon={<IoLogOut />} onClick={logout}>Logout</MenuItem>}
            </Menu>
        </Sidebar>
    );
}

export default SideNavigation;
